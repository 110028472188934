import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ForgotPasswordEmailService } from '../../../../services/AuthService';
import FormAlert from '../../global/forms/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

const ForgetPassword = () => {
  return (
    <div className="text-center">
      <h5 className="mb-0"> Mot de passe oublié ?</h5>
      <small>
        Entrez votre e-mail et nous vous enverrons un lien de réinitialisation.
      </small>
      <ForgetPasswordForm />
    </div>
  );
};

const ForgetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get('email'));
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await executeRecaptcha('check_resetpw_form_mnspf');
        let formDataSubmit = {
          email: email,
          'g-recaptcha-response': token
        };
        const response = await ForgotPasswordEmailService(formDataSubmit);
        if (
          response.message === 'passwords.sent' ||
          response.message === 'passwords.user'
        ) {
          setSubmitDisabled(
            `Un email vient d'être envoyé à ${email} avec un lien de réinitialisation du mot de passe.`
          );
          setErrorForm(false);
        } else if (response.message === 'passwords.throttled') {
          setSubmitDisabled(
            `Vous avez récemment effectué une demande de réinitialisation avec l'adresse ${email}, veuillez vérifier votre boîte de réception et vos spams.`
          );
          setErrorForm(false);
        } else if (response.validator) {
          setErrorForm(response.validator);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
          setIsLoading(false);
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha />
        <Button
          className="w-100"
          onClick={handleSubmit}
          disabled={!email || isLoading}
        >
          Envoyer le lien de réinitialisation
        </Button>
      </div>
    );
  };

  return (
    <Form className="mt-4">
      {submitDisabled && <FormAlert variant="success" data={submitDisabled} />}
      {errorForm && <FormAlert variant="danger" data={errorForm} />}
      {!submitDisabled && (
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={'Adresse email'}
            value={email}
            name="email"
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </Form.Group>
      )}

      {!submitDisabled && (
        <Form.Group className="mb-3">
          <GoogleReCaptchaProvider
            reCaptchaKey="6LcN7GEmAAAAAKZAQHrDR11FvWmCPIpKXrti52Nf"
            language="fr-FR"
          >
            <ReComponent />
          </GoogleReCaptchaProvider>
        </Form.Group>
      )}

      <Link className="fs--1 text-600" to={`/login`}>
        {!submitDisabled ? 'Annuler' : "Retour à l'écran de connexion"}
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPassword;
