export default class HttpService {
  getApiUrl() {
    if (process.env.NODE_ENV === 'production') {
      return 'https://api.docteurwordpress.fr/api';
    } else {
      return 'http://127.0.0.1:8000/api';
    }
  }

  getData = async added_url => {
    const token = await localStorage.getItem('accessToken');
    const requestOptions = this.getRequestOptions(token);
    return fetch(this.getApiUrl() + '/' + added_url, requestOptions).then(
      response => response.json()
    );
  };
  postData = async (item, added_url) => {
    const token = await localStorage.getItem('accessToken');
    const requestOptions = this.postRequestOptions(token, item);
    return fetch(this.getApiUrl() + '/' + added_url, requestOptions).then(
      response => response.json()
    );
  };
  putData = async (item, added_url) => {
    const token = await localStorage.getItem('accessToken');
    const requestOptions = this.putRequestOptions(token, item);
    return fetch(this.getApiUrl() + '/' + added_url, requestOptions).then(
      response => response.json()
    );
  };
  deleteData = async added_url => {
    const token = await localStorage.getItem('accessToken');
    const requestOptions = this.deleteRequestOptions(token);
    return fetch(this.getApiUrl() + '/' + added_url, requestOptions).then(
      response => response.json()
    );
  };
  getRequestOptions = token => {
    let requestOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
        Accept: 'application/json'
      }
    };
    return requestOptions;
  };
  postRequestOptions = (token, item) => {
    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(item)
    };
    return requestOptions;
  };
  putRequestOptions = (token, item) => {
    let requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(item)
    };
    return requestOptions;
  };
  deleteRequestOptions = token => {
    let requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
        Accept: 'application/json'
      }
    };
    return requestOptions;
  };
}
